import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IBlog } from "../internal/internalComponents/blogEditor/BlogEditorModules";
import "./BlogPage.css";
import CallToActionApp from "../components/CallToActionApp";
import BlogPreview from "../components/blog/BlogPreview";
import axios from "axios";

const BlogPage: React.FC = () => {
  const [blogs, setBlogs] = useState<IBlog[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          "https://9hpez190i6.execute-api.us-east-1.amazonaws.com/prod"
        );
        console.log("Received data:", response.data);
        setBlogs(JSON.parse(response.data.body));
        setLoading(false);
      } catch (error) {
        console.error("Error fetching blogs:", error);
        setError("Failed to fetch blogs. Please try again later.");
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="blog-page">
      <h1>Latest Blogs</h1>
      <div className="blog-list">
        {blogs.map((blog) => (
          <Link to={`/blog/${blog.id}`} key={blog.id} className="blog-link">
            <BlogPreview blog={blog} />
          </Link>
        ))}
      </div>
      <br/>
      <div>
        <CallToActionApp />
      </div>
    </div>
  );
};

export default BlogPage;
