import React from "react";
import useTypedTranslation from "../../i18n/useTypedTranslation";

const ImprintPage: React.FC = () => {
  const { t } = useTypedTranslation();
  return (
    <div>
      <h1>{t("Imprint")}</h1>
      <div>
        <h3>Details according to § 5 TMG (German Telemedia Act)</h3>
        <p>Basar Digital UG</p>
        <p>Kolonnenstrasse 8, 10827 Berlin</p>
      </div>
      <br />
      <div>
        <h3>{t("contact")}</h3>
        <p>basar@basar-digital.com</p>
        <p>Managing Director: Mohamad Karadaghi</p>
        <p>Amtsgericht Charlottenburg, HRB 268108 B</p>
      </div>
      <div>
        <h3>Dispute Resolution</h3>
        <p>
          The European Commission provides a platform for online dispute
          resolution: https://ec.europa.eu/consumers/odr. We are not willing or
          obliged to participate in dispute resolution proceedings before a
          consumer arbitration board. The imprint also applies to our current
          social media accounts.
        </p>
      </div>
    </div>
  );
};

export default ImprintPage;
