import React, { useState, useEffect } from "react";
import "./VendorBrowserPage.css";
import NormalTextComponent from "../../styles/NormalTextComponent";
import HeaderTextComponent from "../../styles/HeaderTextComponent";
import VendorVoteSearch from "../../components/partner/UserVendorVote";
import VendorCardLikes from "../../components/VendorCardLikes";
import UserVendorSearch from "../../components/partner/UserVendorSearch";

const VendorBrowserPage: React.FC = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [hasVoted, setHasVoted] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    checkUserVoteStatus();
  }, []);

  const checkUserVoteStatus = async () => {
    try {
      const userIpAddress = await getUserIpAddress();
      const response = await fetch(
        `https://galcaapml9.execute-api.eu-west-1.amazonaws.com/prod/checkUserIpAdress?user_ip=${encodeURIComponent(
          userIpAddress
        )}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setHasVoted(data.hasVoted);
      } else {
        console.error("Fehler beim Überprüfen des Abstimmstatus");
      }
    } catch (error) {
      console.error("Fehler:", error);
    } finally {
      setLoading(false);
    }
  };

  const getUserIpAddress = async () => {
    const response = await fetch("https://api.ipify.org?format=json");
    const { ip } = await response.json();
    return ip;
  };

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const closePopup = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      setIsPopupOpen(false);
    }
  };

  if (loading) {
    return <div>Laden...</div>;
  }

  return (
    <div className="container">
      <div className="one-grid-container">
        <UserVendorSearch />
      </div>
      <div className="two-grid-container">
        <div className="grid-item">
          <HeaderTextComponent level={2} title={""} seoDescription={""}>
            Fehlt dir ein Händler?
          </HeaderTextComponent>
          <NormalTextComponent size={"M"}>
            Es kommen stetig neue Händler dazu. Wenn dir aber einer fehlt, dann
            sprich doch deinen lokalen Händler gerne beim nächsten Besuch darauf
            an!
          </NormalTextComponent>
          {hasVoted ? (
            <div className="confirmation-message">
              <NormalTextComponent size={"M"}>
                Danke für deine Empfehlung!
              </NormalTextComponent>
            </div>
          ) : (
            <>
              <label htmlFor="vendor-search">
                Oder schlag ihn gerne hier vor & erhalte 3 Monate gratis!
              </label>
              <div id="vendor-vote-search">
                <VendorVoteSearch />
              </div>
            </>
          )}
        </div>
        <div className="grid-item">
          <HeaderTextComponent level={2} title={""} seoDescription={""}>
            Hier sind die{" "}
            <span style={{ color: "var(--basar-dark-green)" }}>
              Most-Wanted
            </span>{" "}
            Shops von euch.
          </HeaderTextComponent>
          <NormalTextComponent size={"M"}>
            Basierend auf die Anzahl an Empfehlungen von euch.
          </NormalTextComponent>
          <div className="top-shops-box" onClick={togglePopup}>
            <h2>Top 5 Empfohlene Händler</h2>
            <VendorCardLikes slice={5} />
            <NormalTextComponent
              size={"M"}
              style={{ color: "var(--basar-dark-green)" }}
            >
              Mehr Anzeigen ➡
            </NormalTextComponent>
          </div>
        </div>
      </div>
      {isPopupOpen && (
        <div className="popup-container" onClick={closePopup}>
          <div className="popup-content">
            <button className="close-popup" onClick={togglePopup}>
              &times;
            </button>
            <HeaderTextComponent level={2} title={""} seoDescription={""}>
              Alle Empfohlenen Händler
            </HeaderTextComponent>
            <VendorCardLikes />
          </div>
        </div>
      )}
    </div>
  );
};
export default VendorBrowserPage;
