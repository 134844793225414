import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { vendorSupabase } from '../vendorSupabase';
import './reset-password.css'; // Importiere die CSS-Datei

const ResetPasswordPage: React.FC = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();

  const accessToken = searchParams.get('access_token');
  const refreshToken = searchParams.get('refresh_token');
  const errorCode = searchParams.get('error');

  useEffect(() => {
    if (errorCode === 'access_denied') {
      setErrorMessage(
        'The email link is invalid or has expired. Please request a new password reset link.'
      );
    } else if (accessToken && refreshToken) {
      vendorSupabase.auth.setSession({
        access_token: accessToken,
        refresh_token: refreshToken,
      });
    }
  }, [accessToken, refreshToken, errorCode]);

  const handleResetPassword = async () => {
    setErrorMessage('');
    setSuccessMessage('');

    if (newPassword !== confirmPassword) {
      setErrorMessage('Passwords do not match.');
      return;
    }

    if (newPassword.length < 8) {
      setErrorMessage('Password must be at least 8 characters long.');
      return;
    }

    setIsLoading(true);

    try {
      const { error } = await vendorSupabase.auth.updateUser({
        password: newPassword,
      });

      if (error) throw error;

      setSuccessMessage('Your password has been reset successfully.');
    } catch (error) {
      setErrorMessage('An error occurred while resetting your password. Please try again.');
      console.error('Password reset error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container">
      <div className="card">
        <h2 className="title">Reset Password</h2>
        {errorMessage && <div className="errorMessage">{errorMessage}</div>}
        {successMessage && <div className="successMessage">{successMessage}</div>}
        <input
          type="password"
          className="input"
          placeholder="New Password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <input
          type="password"
          className="input"
          placeholder="Confirm New Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <button className="button" onClick={handleResetPassword} disabled={isLoading}>
          {isLoading ? 'Resetting...' : 'Reset Password'}
        </button>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
