import React from 'react';
import './TextComponents.css'; // Importiere das zugehörige CSS für die Komponente

interface TextProps {
  size: 'XXL' | 'XL' | 'L' | 'M' | 'S' | 'XS'; // Größe des Textes
  title?: string; // SEO-Titel (optional)
  seoDescription?: string; // SEO-Beschreibung (optional)
  children?: React.ReactNode; // Der anzuzeigende Text
  style?: React.CSSProperties; // Zusätzliche Stile übernehmen
  className?: string; // Zusätzliche Klassen übernehmen
}

const NormalTextComponent: React.FC<TextProps> = ({
  size,
  children,
  title,
  seoDescription,
  style,
  className,
}) => {
  // Funktion zur Berechnung der CSS-Klasse basierend auf der Größe
  const getClassBySize = (): string => {
    switch (size) {
      case 'XXL':
        return 'text-xxl';
      case 'XL':
        return 'text-xl';
      case 'L':
        return 'text-l';
      case 'M':
        return 'text-m';
      case 'S':
        return 'text-s';
      case 'XS':
        return 'text-xs';
      default:
        return 'text-m'; // Standardgröße ist M (16px) für Desktop
    }
  };

  return (
    <span
      title={title}
      className={`NormalTextComponent ${getClassBySize()} ${className}`}
      style={style}
    >
      {/* Meta-Tag für SEO-Beschreibung, falls vorhanden */}
      {seoDescription && <meta name="description" content={seoDescription} />}
      {children} {/* Der anzuzeigende Text */}
    </span>
  );
};

export default NormalTextComponent;
