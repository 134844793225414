import React, { useState, useEffect } from "react";
import axios from "axios";
import "./TMS.css";
import {
  creationDropdownOptions,
  API_BASE_URL,
  ICreateTranslation,
  Owner,
} from "./Configuration";

interface TranslationForm {
  Owner: Owner;
  UsageContext: string;
  SourceText: string;
  TranslatedTexts: { [key: string]: string };
}

const PostNewTranslation: React.FC = () => {
  const [translationForm, setTranslationForm] = useState<TranslationForm>({
    Owner: "Common" as Owner,
    UsageContext: creationDropdownOptions.UsageContext.Common[0],
    SourceText: "",
    TranslatedTexts: {},
  });
  const [savedStates, setSavedStates] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [availableUsageContexts, setAvailableUsageContexts] = useState<
    string[]
  >(creationDropdownOptions.UsageContext.Common);

  useEffect(() => {
    setAvailableUsageContexts(
      creationDropdownOptions.UsageContext[translationForm.Owner]
    );
    setTranslationForm((prev) => ({
      ...prev,
      UsageContext:
        creationDropdownOptions.UsageContext[translationForm.Owner][0],
    }));
  }, [translationForm.Owner]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setTranslationForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleTranslatedTextChange = (culture: string, value: string) => {
    setTranslationForm((prev) => ({
      ...prev,
      TranslatedTexts: {
        ...prev.TranslatedTexts,
        [culture]: value,
      },
    }));
  };

  const handleCreate = async (culture: string) => {
    try {
      const translationToSend: ICreateTranslation = {
        TranslationKey: `BD.${translationForm.Owner}--${translationForm.UsageContext}--${translationForm.SourceText}`,
        TargetCulture: culture,
        Owner: translationForm.Owner,
        UsageContext: translationForm.UsageContext,
        SourceText: translationForm.SourceText,
        TranslatedText: translationForm.TranslatedTexts[culture] || "",
      };
      setSavedStates((prev) => ({ ...prev, [culture]: true }));
      setTimeout(() => {
        setSavedStates((prev) => ({ ...prev, [culture]: false }));
      }, 2000);
      await axios.post(
        `${API_BASE_URL}/createTranslation`,
        JSON.stringify(translationToSend),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      alert(`Translation for ${culture} created successfully!`);
      // Reset only the translated text for this culture
      setTranslationForm((prev) => ({
        ...prev,
        TranslatedTexts: {
          ...prev.TranslatedTexts,
          [culture]: "",
        },
      }));
    } catch (error) {
      console.error("Error creating translation:", error);
      alert(`Error creating translation for ${culture}. Please try again.`);
    }
  };

  const handleSearch = async () => {
    const translationKey = `BD.${translationForm.Owner}--${translationForm.UsageContext}--${translationForm.SourceText}`;
    console.log("Searching for TranslationKey:", translationKey);
    try {
      const response = await axios.get(
        `https://fqam2fxxz6.execute-api.eu-west-1.amazonaws.com/prod/getTranslatedTexts?TranslationKey=${translationKey}`
      );
      console.log("API Response:", response.data);
      const translations = response.data;

      if (translations && translations.Translations) {
        setTranslationForm((prev) => ({
          ...prev,
          Owner: translations.Owner || prev.Owner,
          UsageContext: translations.UsageContext || prev.UsageContext,
          SourceText: translations.SourceText || prev.SourceText,
          TranslatedTexts: translations.Translations,
        }));
        console.log("Updated TranslationForm:", translationForm);
      } else {
        console.log("No translations found for this key");
        alert("No translations found for this key");
      }
    } catch (error) {
      console.error("Error fetching translations:", error);
      alert("Error fetching translations. Please try again.");
    }
  };

  return (
    <div className="container">
      <h1 className="title">Add New Translation</h1>
      <div className="createForm">
        <select
          name="Owner"
          value={translationForm.Owner}
          onChange={handleInputChange}
          required
        >
          {creationDropdownOptions.Owner.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        <select
          name="UsageContext"
          value={translationForm.UsageContext}
          onChange={handleInputChange}
          required
        >
          {availableUsageContexts.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        <input
          name="SourceText"
          value={translationForm.SourceText}
          onChange={handleInputChange}
          placeholder="Source Text"
          required
        />
        <button type="button" onClick={handleSearch}>
          Search
        </button>
        {creationDropdownOptions.TargetCulture.map((culture) => (
          <div key={culture} className="translationEntry">
            <input
              value={translationForm.TranslatedTexts[culture] || ""}
              onChange={(e) =>
                handleTranslatedTextChange(culture, e.target.value)
              }
              placeholder={`Translated Text for ${culture}`}
            />
            <button
              type="button"
              onClick={() => handleCreate(culture)}
              disabled={!translationForm.TranslatedTexts[culture]}
            >
              {savedStates[culture] ? "Saved!" : `Save ${culture}`}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PostNewTranslation;
