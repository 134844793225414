import React, { useEffect, useState } from "react";
import { Editor, EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import {
  useForm,
  SubmitHandler,
  FieldErrorsImpl,
  UseFormRegister,
} from "react-hook-form";
import "./BlogEditorModules.css"; // Importiere die CSS-Datei

// Blog-Editor mit Menüleiste und Editor-Inhalt (Tiptap)
interface MenuBarProps {
  editor: Editor | null;
}
export const MenuBar: React.FC<MenuBarProps> = ({ editor }) => {
  if (!editor) {
    return null;
  }

  const toggleBold = () => {
    editor.chain().focus().toggleBold().run();
  };

  const toggleItalic = () => {
    editor.chain().focus().toggleItalic().run();
  };

  const toggleHeading = () => {
    editor.chain().focus().toggleHeading({ level: 1 }).run();
  };

  return (
    <div className="control-group">
      <div className="button-group">
        <button
          onClick={toggleBold}
          disabled={editor.can().toggleBold()}
          className={editor.isActive("bold") ? "is-active" : ""}
        >
          Bold
        </button>
        <button
          onClick={toggleItalic}
          disabled={editor.can().toggleItalic()}
          className={editor.isActive("italic") ? "is-active" : ""}
        >
          Italic
        </button>
      </div>
    </div>
  );
};

interface TipTapBlogEditorProps {
  content: string;
  onContentChange: (content: string) => void;
}
export const TipTapBlogEditor: React.FC<TipTapBlogEditorProps> = ({
  content,
  onContentChange,
}) => {
  const editor = useEditor({
    extensions: [StarterKit],
    content,
    onUpdate: ({ editor }) => {
      onContentChange(editor.getHTML());
    },
  });

  useEffect(() => {
    if (editor && editor.getHTML() !== content) {
      editor.commands.setContent(content);
    }
  }, [content, editor]);
  return (
    <div className="blog-editor">
      <MenuBar editor={editor} />
      <div
        style={{
          border: "1px solid #ddd",
          minHeight: "200px",
          width: "50%",
          margin: "auto",
          padding: "10px",
        }}
      >
        <EditorContent editor={editor} />
      </div>
    </div>
  );
};

// Datenmodell für Blog-Objekte
export interface IBlog {
  author: string;
  title: string;
  subtitle: string;
  metaTitle: string;
  metaDescription: string;
  contentText: string;
  titleImage: string;
  blogcategory: string;
  publishDate?: string;
  id?: string;
}

// Blog-Editor zum Erstellen und Bearbeiten von Blog-Objekten
// Datenauswahl für die Blogseite
const authors = ["Basar Redaktion", "Mohamad Karadaghi", "Luran Siliman"];
const blogCategories = [
  "Allgemein",
  "Für Partner",
  "Restaurants",
  "Werkstatt",
  "Veranstaltungen",
  "Cafe",
  "Gastronomie",
  "Sonstiges",
];

interface BlogFormProps {
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  register: UseFormRegister<IBlog>;
  errors: FieldErrorsImpl<IBlog>;
  handleSubmit: (onSubmit: SubmitHandler<IBlog>) => void;
  handleInputChange: (field: keyof IBlog, value: string) => void; // Neue Prop für Zustandsänderung
}

const BlogForm: React.FC<BlogFormProps> = ({
  onSubmit,
  register,
  errors,
  handleSubmit,
  handleInputChange,
}) => {
  return (
    <form onSubmit={onSubmit} className="blog-form">
      <label>
        Author:
        <select
          {...register("author", { required: true })}
          onChange={(e) => handleInputChange("author", e.target.value)}
        >
          <option value="">Select an author</option>
          {authors.map((author, index) => (
            <option key={index} value={author}>
              {author}
            </option>
          ))}
        </select>
        {errors.author && <span className="error">This field is required</span>}
      </label>
      <label>
        Title:
        <input
          type="text"
          {...register("title", { required: true })}
          onChange={(e) => handleInputChange("title", e.target.value)}
        />
        {errors.title && <span className="error">This field is required</span>}
      </label>
      <label>
        Subtitle:
        <input type="text" {...register("subtitle", { required: true })} 
        onChange={(e) => handleInputChange("subtitle", e.target.value)}
        />
        {errors.subtitle && (
          <span className="error">This field is required</span>
        )}
      </label>
      <label>
        Meta Title:
        <input type="text" {...register("metaTitle", { required: true })} 
        />
        {errors.metaTitle && (
          <span className="error">This field is required</span>
        )}
      </label>
      <label>
        Meta Description:
        <textarea {...register("metaDescription", { required: true })} />
        {errors.metaDescription && (
          <span className="error">This field is required</span>
        )}
      </label>
      <label>
        Title Image URL:
        <input type="text" {...register("titleImage", { required: true })} 
        onChange={(e) => handleInputChange("titleImage", e.target.value)}
        />
        {errors.titleImage && (
          <span className="error">This field is required</span>
        )}
      </label>
      <label>
        Blog Category:
        <select {...register("blogcategory", { required: true })}
                onChange={(e) => handleInputChange("blogcategory", e.target.value)}
        >
          <option value="">Select a category</option>
          {blogCategories.map((category, index) => (
            <option key={index} value={category}>
              {category}
            </option>
          ))}
        </select>
        {errors.blogcategory && (
          <span className="error">This field is required</span>
        )}
      </label>
    </form>
  );
};
export default BlogForm;
