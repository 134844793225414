import React, { Component } from "react";
import { InternalNavbar } from "../internalComponents/InternalNavbar";

export default class InternalPage extends Component {
  render() {
    return (
      <div>
        <div>
          <h1>Willkommen ADMIN</h1>
        </div>
      </div>
    );
  }
}
