import { Loader } from "@googlemaps/js-api-loader";

const googleMapsApiKey = "AIzaSyCX7kdSkkuO9TbnuxQ3QIWI4NUvtUAepvk";

export const loader = new Loader({
  apiKey: googleMapsApiKey,
  version: "weekly",
  libraries: ["places"],
});

export const loadGoogleMaps = () => loader.load();