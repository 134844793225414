import React from 'react';
import './TextComponents.css'; // Importiere das zugehörige CSS für die Komponente

interface HeadingProps {
  level: 1 | 2 | 3 | 4 | 5 | 6; // HTML-Überschriften-Level
  title: string; // SEO-Titel (optional)
  seoDescription?: string; // SEO-Beschreibung (optional)
  children?: React.ReactNode; // Der anzuzeigende Text
  style?: React.CSSProperties; // Zusätzliche Stile übernehmen
  className?: string; // Zusätzliche Klassen übernehmen
  fontWeight?: 'normal' | 'bold' | 'italic'; // Schriftgewicht
}

const HeaderTextComponent: React.FC<HeadingProps> = ({
  level,
  children,
  title,
  seoDescription,
  style,
  className,
  fontWeight, // Standardwert für Schriftgewicht ist 'bold'
}) => {
  const TagName = `h${level}` as keyof JSX.IntrinsicElements;

  // CSS-Klasse basierend auf dem Überschriften-Level und Schriftgewicht erstellen
  const getClassByLevel = (): string => {
    switch (level) {
      case 1:
        return `heading-h1 ${fontWeight}`;
      case 2:
        return `heading-h2 ${fontWeight}`;
      case 3:
        return `heading-h3 ${fontWeight}`;
      case 4:
        return `heading-h4 ${fontWeight}`;
      case 5:
        return `heading-h5 ${fontWeight}`;
      case 6:
        return `heading-h6 ${fontWeight}`;
      default:
        return `heading-h1 ${fontWeight}`; // Standard ist h1
    }
  };

  return (
    <TagName title={title} className={`HeaderTextComponent ${getClassByLevel()} ${className}`} style={style}>
      {/* Meta-Tag für SEO-Beschreibung, falls vorhanden */}
      {seoDescription && <meta name="description" content={seoDescription} />}
      {children} {/* Der anzuzeigende Text */}
    </TagName>
  );
};

export default HeaderTextComponent;
