import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { loadGoogleMaps } from "./googleMapsLoader";
import "./PartnerSearch.css";

const PartnerSearch: React.FC = () => {
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);
  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);

  // Funktion zum Formatieren der Place Types
  const formatPlaceType = (type: string): string => {
    return type
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  useEffect(() => {
    loadGoogleMaps().then(() => {
      if (inputRef.current) {
        const autocomplete = new google.maps.places.Autocomplete(inputRef.current, {
          types: ["establishment"],
          fields: ["place_id"],
        });
        setAutocomplete(autocomplete);

        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();
          if (place.place_id) {
            handlePlaceSelected(place.place_id);
          }
        });
      }
    });
  }, []);

  const handlePlaceSelected = (placeId: string) => {
    const service = new google.maps.places.PlacesService(document.createElement("div"));
    const request = {
      placeId,
      fields: ["name", "formatted_address", "geometry", "photos", "types"],
    };

    service.getDetails(request, (placeDetails, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK && placeDetails) {
        const photoUrl = placeDetails.photos && placeDetails.photos.length > 0
          ? placeDetails.photos[0].getUrl()
          : "";
        const types = placeDetails.types && placeDetails.types.length > 0
          ? formatPlaceType(placeDetails.types[0])
          : "Dein Geschäft";

        navigate("/partner-detail", {
          state: {
            name: placeDetails.name,
            formatted_address: placeDetails.formatted_address,
            photoUrl,
            placeId: placeDetails.place_id,
            types,
          },
        });
      } else {
        console.error("Error fetching place details:", status);
      }
    });
  };

  return (
    <div className="input-wrapper">
      <input
        ref={inputRef}
        type="text"
        placeholder="🔎  Suche nach deinem Laden..."
        className="autocomplete-input"
      />
    </div>
  );
};

export default PartnerSearch;