import { useTranslation as useI18nextTranslation } from 'react-i18next';
import { TranslationKeys } from './locales';

const useTypedTranslation = () => {
  const { t } = useI18nextTranslation();
  const transkey = (key: TranslationKeys) => t(key);
  return {
    t: transkey,
  };
};
export default useTypedTranslation;
