export const translations = {
  de: {
    change_language: "Sprache ändern",
    english_USA: "🇬🇧 Englisch",
    german_Germany: "🇩🇪 Deutsch",
    Imprint: "Impressum",
    Datenschutz: "Datenschutzerklärung",
    AGB: "Allgemeine Geschäftsbedingungen",
    Cookies: "Cookies",
    about: "ÜBER UNS",
    our_magazine: "Unser Magazin",
    for_companies: "Für Firmen",
    contact: "Kontakt",
    no_answer_found: "Frage nicht beantwortet?",
    jobs: "Jobs",
    become_partner: "Partner werden",
    shop: "Shop",
    experiences: "Erfahrungen",
    legal: "RECHTLICHES",
    social_media: "SOZIALE MEDIEN",
    frequently_asked_questions: "FAQ",
    vendor_faq: {
      how_work_question: "Wie funktioniert BASAR für Partner?",
      how_work_answer:
        "Wir haben Partnerschaften mit verschiedenen lokalen Unternehmen geschlossen und einzigartige Angebote wie 2-für-1-Dienstleistungen oder Rabatte auf Produkte ausgehandelt. Mit der Basar-App können Sie diese Angebote in zahlreichen Branchen wie Restaurants, Friseuren, Werkstätten, Blumenläden und vielen anderen ansehen, buchen und einlösen.",
      participating_businesses_question: "Welche Unternehmen nehmen teil?",
      participating_businesses_answer:
        "Derzeit nehmen tausende lokale Unternehmen mit einer Vielzahl exklusiver Angebote teil. Von Gastronomie über Dienstleistungen bis hin zu Produkten finden Sie tolle Angebote in vielen Kategorien.",
      app_free_question: "Ist die App derzeit kostenlos?",
      app_free_answer:
        "Ja, die BASAR-App ist derzeit vollständig kostenlos. Partner können teilnehmen und Angebote ohne Gebühren bereitstellen.",
      create_store_question: "Wie erstelle ich ein Geschäft auf BASAR?",
      create_store_answer:
        "Ihr Geschäft bei BASAR zu erstellen, ist einfach und schnell. Sie können Ihr Geschäftsprofil in wenigen Klicks direkt in der App einrichten.",
      manage_coupons_question:
        "Kann ich meine eigenen Coupons erstellen und verwalten?",
      manage_coupons_answer:
        "Ja, Sie haben die vollständige Kontrolle über das Erstellen und Löschen Ihrer eigenen Coupons. Legen Sie Rabatte, Angebote und Bedingungen fest, die am besten zu Ihrem Geschäft passen. Keine Sales Manager stören – Ihr Geschäft, Ihr Marketing.",
    },
    faq: {
      general: {
        category: "ALLGEMEINES",
        questions: [
          {
            question: "Was ist das Konzept von Basar?",
            answer:
              "Wir haben Partnerschaften mit verschiedenen lokalen Unternehmen geschlossen und exklusive Einführungsgutscheine ausgehandelt, wie z. B. 2-für-1-Angebote oder Rabatte auf Produkte. Mit der Basar-App kannst du diese Angebote ganz einfach durchstöbern, buchen und einlösen und das in einer Vielzahl von Branchen wie Restaurants, Friseursalons, Werkstätten, Blumenläden und mehr.",
          },
          {
            question: "Welche Unternehmen nehmen teil?",
            answer:
              "Derzeit nehmen Tausende von lokalen Unternehmen mit einer Vielzahl exklusiver Angebote teil. Von Gastronomie bis zu Dienstleistungen und Produkten findest du in vielen Kategorien großartige Deals.",
          },
        ],
      },
      free_trial: {
        category: "KOSTENLOSE TESTPHASE",
        questions: [
          {
            question: "Wie kann ich die Testphase kündigen?",
            answer:
              'Gehe einfach zu "Profil" → "Mitgliedschaft" → "Mitgliedschaft kündigen".',
          },
        ],
      },
      membership: {
        category: "MITGLIED WERDEN",
        questions: [
          {
            question: "Wie werde ich Basar-Mitglied?",
            answer:
              'Melde dich einfach in der App an! Entweder direkt nach der Registrierung oder über den "Mitglied werden"-Button auf dem Hauptbildschirm.',
          },
          {
            question: "Welche Zahlungsmöglichkeiten gibt es?",
            answer:
              "Wir akzeptieren Zahlungen per PayPal, Kreditkarte und Debitkarte.",
          },
          {
            question: "Wie kann ich mein Konto löschen?",
            answer:
              'Am einfachsten ist es über die App. Gehe zu "Profil" → "Einstellungen" → "Profil bearbeiten" und wähle dann "Konto löschen". Bei Fragen kontaktiere uns gerne!',
          },
        ],
      },
      book_deal: {
        category: "EIN ANGEBOT BUCHEN",
        questions: [
          {
            question: "Wie buche ich ein Angebot?",
            answer:
              "Wähle ein Geschäft auf der Karte oder in der Listenansicht, suche dir einen Zeitpunkt aus, zu dem du das Angebot einlösen möchtest, und buche es. Schon bist du bereit!",
          },
          {
            question: "Ist ein Tisch reserviert, wenn ich ein Angebot buche?",
            answer:
              "Nein, die Buchung eines Angebots beinhaltet keine Tischreservierung. Es ist ratsam, zusätzlich einen Tisch zu reservieren. Falls kein Tisch verfügbar ist, kannst du das Angebot einfach stornieren und einen anderen Termin buchen.",
          },
          {
            question:
              "Kann ich die Angebote auch für Lieferung und Abholung nutzen?",
            answer:
              "Die Idee hinter Basar ist es, neue lokale Unternehmen zu entdecken und den Besuch vor Ort zu einem ganzheitlichen Erlebnis zu machen. Daher gelten Angebote in der Regel nicht für Lieferungen oder Abholung – allerdings können dies die Geschäftsinhaber selbst entscheiden. Fragen lohnt sich also.",
          },
          {
            question:
              "Was passiert, wenn ich ein gebuchtes Angebot nicht einlöse?",
            answer:
              "Nicht eingelöste Angebote werden über Nacht automatisch freigegeben und können erneut gebucht werden.",
          },
        ],
      },
      redeem_deal: {
        category: "ANGEBOT EINLÖSEN",
        questions: [
          {
            question: "Wie löse ich ein Angebot ein?",
            answer:
              "Zeige dein gebuchtes Angebot beim Bestellen oder bei der Nutzung einer Dienstleistung im Geschäft vor, um das Personal zu informieren. Beim Bezahlen zeigst du das Angebot erneut und validierst es: Klicke auf Einlösen und bestätige durch Wischen, fertig!",
          },
          {
            question: "Wie oft kann ein Angebot eingelöst werden?",
            answer:
              "Die meisten Angebote können alle 6 Monate eingelöst werden. Die Idee hinter Basar ist es, viele verschiedene lokale Unternehmen auszuprobieren!",
          },
        ],
      },
      refer_basar: {
        category: "BASAR EMPFEHLEN",
        questions: [
          {
            question: "Wie kann ich Basar weiterempfehlen?",
            answer:
              'Über "Profil" → "Basar empfehlen". Dein persönlicher Code befindet sich unten, den du mit deinen Freunden teilen kannst. Für jede Person, die du einlädst, erhältst du 1 Monat Basar gutgeschrieben.',
          },
          {
            question:
              "Ich habe einen Empfehlungscode erhalten – wo gebe ich diesen ein?",
            answer:
              'Nach dem Herunterladen der App kannst du ein Konto erstellen und dann den Code über "Ich habe einen Gutscheincode" eingeben. Alternativ kannst du über den Button "Mitglied werden" auf dem Hauptbildschirm wieder zu dieser Seite gelangen.',
          },
        ],
      },
    },
  },
  en: {
    change_language: "Change Language",
    english_USA: "🇬🇧 English",
    german_Germany: "🇩🇪 German",
    Imprint: "Imprint",
    Datenschutz: "Privacy Policy",
    AGB: "Terms and Conditions",
    Cookies: "Cookies",
    about: "ABOUT",
    our_magazine: "Our Magazine",
    for_companies: "For Companies",
    contact: "Contact",
    no_answer_found: "Other Questions?",
    jobs: "Jobs",
    become_partner: "Become a Partner",
    shop: "Shop",
    experiences: "Experiences",
    legal: "LEGAL",
    social_media: "SOCIAL MEDIA",
    frequently_asked_questions: "FAQ",
    vendor_faq: {
      how_work_question: "How does BASAR for partners work?",
      how_work_answer:
        "We have partnered with various local businesses and negotiated unique introductory deals with them, such as 2-for-1 services or discounts on products. With the Basar app, you can easily view, book, and redeem these deals across a wide range of industries including restaurants, hair salons, workshops, flower shops, and more.",
      participating_businesses_question: "Which businesses are participating?",
      participating_businesses_answer:
        "Currently, thousands of local businesses are participating with a wide variety of exclusive offers. From dining to services and products, you can find great deals in many categories.",
      app_free_question: "Is the app currently free?",
      app_free_answer:
        "Yes, the BASAR app is currently completely free to use. Partners can join and offer deals without any fees at this time.",
      create_store_question: "How can I create a store on BASAR?",
      create_store_answer:
        "Creating your store on BASAR is quick and easy. You can set up your store profile in just a few clicks, directly in the app.",
      manage_coupons_question: "Can I create and manage my own coupons?",
      manage_coupons_answer:
        "Yes, you have full control over creating and deleting your own coupons. Set the discounts, deals, and terms that work best for your business. No sales managers will interrupt—your store, your marketing.",
    },
    faq: {
      general: {
        category: "GENERAL",
        questions: [
          {
            question: "What is the concept of Basar?",
            answer:
              "We have partnered with various local businesses and negotiated unique introductory deals with them, such as 2-for-1 services or discounts on products...",
          },
          {
            question: "Which businesses are participating?",
            answer:
              "Currently, thousands of local businesses are participating with a wide variety of exclusive offers...",
          },
        ],
      },
      free_trial: {
        category: "FREE TRIAL",
        questions: [
          {
            question: "How can I cancel the trial phase?",
            answer:
              'Simply go to "Profile" → "Membership" → "Cancel Membership".',
          },
        ],
      },
      membership: {
        category: "BECOME A MEMBER",
        questions: [
          {
            question: "How can I become a Basar member?",
            answer:
              "Simply sign up in the app! Either directly after registration or through the Join button on the main screen.",
          },
          {
            question: "What payment options are available?",
            answer: "We accept PayPal, credit card, and debit card payments.",
          },
          {
            question: "How can I delete my account?",
            answer:
              'The easiest way is in the app. Go to "Profile -> Settings -> Edit profile" and then "Delete account"...',
          },
        ],
      },
      book_deal: {
        category: "BOOK A DEAL",
        questions: [
          {
            question: "How do I book a deal?",
            answer:
              "Select a business on the map or in the list view, choose a time slot when you want to redeem the deal...",
          },
          {
            question: "Is a table reserved when I book a deal?",
            answer:
              "No, booking a deal does not include a table reservation. It's best to additionally reserve a table to be on the safe side...",
          },
          {
            question: "Can I use the deals for delivery and takeout as well?",
            answer:
              "The idea behind Basar is to discover new local businesses and make the on-site visit a complete experience...",
          },
          {
            question: "What happens if I don't redeem a booked deal?",
            answer:
              "Unused booked deals are automatically released overnight and can be booked again.",
          },
        ],
      },
      redeem_deal: {
        category: "REDEEM DEAL",
        questions: [
          {
            question: "How do I redeem a deal?",
            answer:
              "Show your booked deal when placing an order or using a service at the business...",
          },
          {
            question: "How often can a deal be redeemed?",
            answer:
              "Most deals can be redeemed every 6 months. The idea behind Basar is to try out many different local businesses!",
          },
        ],
      },
      refer_basar: {
        category: "REFER BASAR",
        questions: [
          {
            question: "How can I refer Basar?",
            answer:
              'Through "Profile" → "Refer Basar". Your personal code is at the bottom, which you can share with your friends...',
          },
          {
            question: "I received a referral code – where do I enter the code?",
            answer:
              'After downloading the app, you can create an account and then enter the code through "I have a voucher code"...',
          },
        ],
      },
    },
  },
};

// Typen für Übersetzungsschlüssel und verfügbare Lokalisierungen
type NestedKeyOf<ObjectType extends object> = {
  [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object
    ? `${Key}` | `${Key}.${NestedKeyOf<ObjectType[Key]>}`
    : `${Key}`;
}[keyof ObjectType & (string | number)];
export type TranslationKeys = NestedKeyOf<(typeof translations)["de"]>;
export type Locales = keyof typeof translations;
