import React, { useState } from "react";

// Nutzer-AGB-Bilder
import agb1 from "./docs/Terms of use-01.png";
import agb2 from "./docs/Terms of use-02.png";
import agb3 from "./docs/Terms of use-03.png";
import agb4 from "./docs/Terms of use-04.png";
import agb5 from "./docs/Terms of use-05.png";
import agb6 from "./docs/Terms of use-06.png";
import agb7 from "./docs/Terms of use-07.png";
import agb8 from "./docs/Terms of use-08.png";
import agb9 from "./docs/Terms of use-09.png";
import agb10 from "./docs/Terms of use-10.png";
import agb11 from "./docs/Terms of use-11.png";
import agb12 from "./docs/Terms of use-12.png";
import agb13 from "./docs/Terms of use-13.png";

// Partner-AGB-Bilder
import partnerAgb1 from "./docs/partnerAgb1.png";
import partnerAgb2 from "./docs/partnerAgb2.png";
import partnerAgb3 from "./docs/partnerAgb3.png";
import partnerAgb4 from "./docs/partnerAgb4.png";
import { dS } from "../../designSystem/designSystem";

const TermsAndConditionsPage: React.FC = () => {
  const [showUserAgbs, setShowUserAgbs] = useState(true);
  const [showPartnerAgbs, setShowPartnerAgbs] = useState(false);

  // Listen der importierten Bilder
  const userAgbImages = [
    agb1,
    agb2,
    agb3,
    agb4,
    agb5,
    agb6,
    agb7,
    agb8,
    agb9,
    agb10,
    agb11,
    agb12,
    agb13,
  ];

  const partnerAgbImages = [partnerAgb1, partnerAgb2, partnerAgb3, partnerAgb4];

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "white" }}
    >
      <h1>Allgemeine Geschäftsbedingungen (AGB)</h1>
      <p>Wählen Sie die entsprechende AGB aus:</p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          width: "30%",
          alignItems: "center",
          justifyContent: "center",
          padding: "20px",
          borderRadius: "8px",
        }}
      >
        <button
          onClick={() => {
            setShowUserAgbs(true);
            setShowPartnerAgbs(false);
          }}
          style={{
            backgroundColor: showUserAgbs
              ? dS.colors.basarColorRed
              : dS.colors.basarColorGrey,
            color: showUserAgbs ? "white" : "black",
            padding: "10px 20px",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          AGB für Nutzer anzeigen
        </button>
        <button
          onClick={() => {
            setShowUserAgbs(false);
            setShowPartnerAgbs(true);
          }}
          style={{
            backgroundColor: showPartnerAgbs
              ? dS.colors.basarColorRed
              : dS.colors.basarColorGrey,
            color: showPartnerAgbs ? "white" : "black",
            padding: "10px 20px",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          AGB für Partner anzeigen
        </button>
      </div>

      {/* Nutzer AGB anzeigen */}
      {showUserAgbs && (
        <div
          style={{
            marginTop: "20px",
            width: "80%",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          {userAgbImages.map(
            (src, index) =>
              index % 2 === 0 && (
                <div key={index} style={{ display: "flex", gap: "10px" }}>
                  <img
                    src={userAgbImages[index]}
                    alt={`AGB Seite ${index + 1}`}
                    style={{ width: "50%" }}
                  />
                  {userAgbImages[index + 1] && (
                    <img
                      src={userAgbImages[index + 1]}
                      alt={`AGB Seite ${index + 2}`}
                      style={{ width: "50%" }}
                    />
                  )}
                </div>
              )
          )}
        </div>
      )}

      {/* Partner AGB anzeigen */}
      {showPartnerAgbs && (
        <div
          style={{
            marginTop: "20px",
            width: "80%",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          {partnerAgbImages.map(
            (src, index) =>
              index % 2 === 0 && (
                <div key={index} style={{ display: "flex", gap: "10px" }}>
                  <img
                    src={partnerAgbImages[index]}
                    alt={`Partner AGB Seite ${index + 1}`}
                    style={{ width: "50%" }}
                  />
                  {partnerAgbImages[index + 1] && (
                    <img
                      src={partnerAgbImages[index + 1]}
                      alt={`Partner AGB Seite ${index + 2}`}
                      style={{ width: "50%" }}
                    />
                  )}
                </div>
              )
          )}
        </div>
      )}
    </div>
  );
};

export default TermsAndConditionsPage;
