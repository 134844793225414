import React, { useState, useRef } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "./UserVendorSearch.css";
import NormalTextComponent from "../../styles/NormalTextComponent";
import L from "leaflet";
import BASARPOI from "../../assets/images/iconBasarPOI.svg";
import SearchBar from "../SearchBar";

// Fix für das Marker-Icon-Problem in React-Leaflet
delete (L.Icon.Default.prototype as any)._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

interface Vendor {
  id: string;
  name: string;
  lat: number;
  lng: number;
}

interface City {
  name: string;
  lat: number;
  lng: number;
}

const cities: City[] = [
  { name: "Berlin", lat: 52.520008, lng: 13.404954 },
  { name: "Hamburg", lat: 53.551086, lng: 9.993682 },
  { name: "München", lat: 48.137154, lng: 11.576124 },
  { name: "Köln", lat: 50.937531, lng: 6.960279 },
  { name: "Frankfurt am Main", lat: 50.110924, lng: 8.682127 },
  { name: "Stuttgart", lat: 48.783333, lng: 9.183333 },
  { name: "Düsseldorf", lat: 51.233334, lng: 6.783333 },
  { name: "Leipzig", lat: 51.340199, lng: 12.360103 },
  { name: "Dortmund", lat: 51.513587, lng: 7.465298 },
  { name: "Essen", lat: 51.458069, lng: 7.014761 },
];

const redIcon = L.icon({
  iconUrl: BASARPOI,
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  iconSize: [50, 50],
});



const UserVendorSearch: React.FC = () => {
  const mapRef = useRef<L.Map>(null);
  const [vendors] = useState<Vendor[]>([
    { id: "1", name: "Berliner Currywurst", lat: 52.520008, lng: 13.404954 },
    { id: "2", name: "Münchner Biergarten", lat: 48.137154, lng: 11.576124 },
    { id: "3", name: "Hamburger Fischmarkt", lat: 53.544388, lng: 9.977394 },
  ]);
  const [selectedCity, setSelectedCity] = useState<City | null>(null);

  const [mapCenter, setMapCenter] = useState<[number, number]>([51.1657, 10.4515]);
  const [zoom, setZoom] = useState(6);

  const handleCitySelect = (city: City) => {
    setSelectedCity(city);
    setMapCenter([city.lat, city.lng]);
    setZoom(12);

    // Nach dem State-Update die Karte zentrieren
    if (mapRef.current) {
      mapRef.current.setView([city.lat, city.lng], 12);
    }
  };


  return (
    <div className="user-vendor-search-container">
      <div className="header">
        <h1>
          Wer ist schon alles{" "}
          <span style={{ color: "var(--basar-red)" }}>digital</span> auf{" "}
          <span style={{ color: "var(--basar-red)" }}>Basar?</span>
        </h1>
      </div>
      <div className="search-grid">
        <div className="left-grid">
          <div className="form-group">
            <h3
              style={{
                fontWeight: "lighter",
                color: "var(--basar-dark-grey)",
              }}
            >
              Suche hier nach aktuellen Partner Geschäften.
            </h3>
            <SearchBar
              label="Stadt"
              items={cities}
              onSelect={handleCitySelect}
              placeholder="Stadt eingeben"
              icon="🔎"
              maxResults={5}
            />
          </div>

          <div>
            <button>Suchen</button>
            <h3
              style={{
                fontWeight: "lighter",
                color: "var(--basar-dark-grey)",
              }}
            >
              Fehlt dir ein Geschäft? Dann kannst du es{" "}
              <a href="#vendor-vote-search">hier gerne empfehlen</a>.
            </h3>
          </div>
        </div>
        <div className="right-grid">
          <MapContainer
            center={mapCenter}
            zoom={zoom}
            className="custom-popup"
            style={{
              height: "400px",
              width: "100%",
              border: "2px solid var(--basar-light-grey)",
              borderRadius: "10px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            }}
            ref={mapRef}
          >
            <TileLayer
              url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
            />
            {vendors.map((vendor) => (
              <Marker
                icon={redIcon}
                key={vendor.id}
                position={[vendor.lat, vendor.lng]}
              >
                <Popup>{vendor.name}</Popup>
              </Marker>
            ))}
          </MapContainer>
        </div>
      </div>
    </div>
  );
};

export default UserVendorSearch;
