import React, { useState } from "react";
import axios, { AxiosError } from "axios";
import { useForm, SubmitHandler } from "react-hook-form";

import "../internalComponents/blogEditor/BlogEditorModules.css";
import BlogForm, { IBlog } from "../internalComponents/blogEditor/BlogEditorModules";
import TipTapBlogEditor from "../internalComponents/blogEditor/BlogTipTapEditor";

const BlogEditor: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IBlog>();
  const [content, setContent] = useState<string>("");
  const handleContentChange = (newContent: string) => {
    setContent(newContent);
  };

  // für livepreview vom blog
  const handleInputChange = (field: keyof IBlog, value: string) => {
    setFormData({ ...formData, [field]: value });
  };

  const [formData, setFormData] = useState<IBlog>({
    author: "",
    title: "",
    subtitle: "",
    metaTitle: "",
    metaDescription: "",
    contentText: "",
    titleImage: "",
    blogcategory: "",
  });

  const onSubmit: SubmitHandler<IBlog> = async (data) => {
    data.contentText = content;
    try {
      const response = await axios.post(
        "https://3sjjb6vzfc.execute-api.us-east-1.amazonaws.com/prod/",
        data
      );
      console.log("Response:", response.data);
      alert("Blog entry submitted successfully!");
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          console.error("Response data:", axiosError.response.data);
          console.error("Response status:", axiosError.response.status);
          console.error("Response headers:", axiosError.response.headers);
        } else if (axiosError.request) {
          console.error("Request:", axiosError.request);
        }
      } else {
        console.error("Unknown error:", error);
      }
      alert("Error submitting blog entry. Please try again.");
    }
  };

  return (
    <div className="container">
      <div className="form-container">
        <h1>Blog Editor</h1>
        <BlogForm
          onSubmit={handleSubmit(onSubmit)}
          register={register}
          errors={errors}
          handleSubmit={handleSubmit}
          handleInputChange={handleInputChange}
        />
        <label>
          <h2 style={{ paddingTop: "20px" }}>Content Text*</h2>
          <TipTapBlogEditor
            content={content}
            onContentChange={handleContentChange}
          />
        </label>
        <button
          style={{
            width: "30%",
            padding: "10px",
            margin: "10px",
            backgroundColor: "red",
            color: "white",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
          }}
          type="submit"
          onClick={handleSubmit(onSubmit)}
        >
          Submit
        </button>
      </div>

      {/* Live Vorschau */}
      <div className="blogpreview-container">
        <h1 style={{ color: "var(--basar-grey)" }}>Live Vorschau</h1>
        <div>
          <p>Beitrag von: {formData.author}</p>
          <p>{formData.blogcategory}</p>
          <h2>{formData.title}</h2>
          <h3>{formData.subtitle}</h3>
          <img
            src={formData.titleImage}
            alt={formData.title}
            style={{ width: "200px", height: "100px" }}
          />
          <div
            className="blogpreview-content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </div>

    </div>
  );
};

export default BlogEditor;
