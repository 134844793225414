import React from "react";
import { Link } from "react-router-dom";
import "./BlogPreview.css";
import { IBlog } from "../../internal/internalComponents/blogEditor/BlogEditorModules";

// Fallback-Bild URL
const fallbackImageUrl = "https://cloudinary-marketing-res.cloudinary.com/images/w_1000,c_scale/v1699909962/fallback_image_header/fallback_image_header-png?_i=AA";

interface BlogPreviewProps {
  blog: IBlog;
}

const BlogPreview: React.FC<BlogPreviewProps> = ({ blog }) => {
  // Definieren Sie die Optionen für die Datumsformatierung
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
  };

  // Überprüfen Sie, ob publishDate definiert ist und vom Typ string
  const formattedDate =
    typeof blog.publishDate === "string"
      ? new Date(blog.publishDate).toLocaleDateString("de-DE", options)
      : "Kein Datum";

  // Fallback-Bild verwenden, wenn blog.titleImage leer ist
  const imageUrl = blog.titleImage || fallbackImageUrl;

  // Maximal angezeigte Anzahl von Zeichen für den Untertitel
  const maxSubtitleLength = 100;

  // Kürzen Sie den Untertitel, wenn er zu lang ist
  const truncatedSubtitle = blog.subtitle && blog.subtitle.length > maxSubtitleLength
    ? `${blog.subtitle.slice(0, maxSubtitleLength)}...`
    : blog.subtitle;

  return (
    <div className="blog-preview-card">
      <Link to={`/blog/${blog.id}`} className="blog-link">
        <img src={imageUrl} alt={blog.title} />
        <div className="blog-preview-content">
          <h3>{blog.title}</h3>
          {truncatedSubtitle && <p className="subtitle">{truncatedSubtitle}</p>}
          <p>{formattedDate}</p>
        </div>
      </Link>
    </div>
  );
};

export default BlogPreview;
