import React, { useEffect, useState } from "react";
import axios from "axios";
import "./VendorCardLikes.css";

interface Vendor {
  place_id: string;
  address: string;
  category: string;
  first_photo: string | null;
  like_count: number;
  name: string;
}

interface VendorRecommenderProps {
  slice?: number;
}

const VendorRecommender: React.FC<VendorRecommenderProps> = ({ slice }) => {
  const [vendors, setVendors] = useState<Vendor[]>([]);

  useEffect(() => {
    async function fetchVendors() {
      try {
        const response = await axios.get(
          "https://pbpcwvp15e.execute-api.eu-west-1.amazonaws.com/prod/"
        );
        if (response.data && response.data.body) {
          const parsedData = JSON.parse(response.data.body);
          if (Array.isArray(parsedData)) {
            setVendors(parsedData);
          } else {
            console.error("Unerwartetes Datenformat:", parsedData);
            setVendors([]);
          }
        } else {
          console.error("Unerwartetes Antwortformat:", response.data);
          setVendors([]);
        }
      } catch (error: any) {
        console.error("Fehler beim Abrufen der Anbieter:", error);
        setVendors([]);
      }
    }

    fetchVendors();
  }, []);

  const displayedVendors = Array.isArray(vendors)
    ? vendors.slice(0, slice)
    : [];

  return (
    <div className="vendor-list">
      {displayedVendors.map((vendor) => (
        <div key={vendor.place_id} className="vendor-card">
          <div className="vendor-image">
            {vendor.first_photo && (
              <img src={vendor.first_photo} alt={vendor.name} />
            )}
          </div>
          <div className="vendor-info">
            <h3 className="vendor-name">{vendor.name}</h3>
            <p className="vendor-address">{vendor.address}</p>
            <p className="vendor-category">{vendor.category}</p>
          </div>
          <div className="vendor-likes">
            <span className="like-count">+{vendor.like_count}💚</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default VendorRecommender;
