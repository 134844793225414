import {
  Editor,
  EditorContent,
  EditorProvider,
  useEditor,
} from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import TextAlign from "@tiptap/extension-text-align";
import { useEffect, useState } from "react";
import Link from "@tiptap/extension-link";

// Beispiel für die Verwendung von EditorProvider zur Initialisierung des Editors
export const TipTapBlogEditorWrapper: React.FC = () => {
  const [content, setContent] = useState<string>("<p>Initialer Inhalt</p>");

  const handleContentChange = (newContent: string) => {
    setContent(newContent);
  };

  return (
    <EditorProvider>
      <TipTapBlogEditor
        content={content}
        onContentChange={handleContentChange}
      />
    </EditorProvider>
  );
};

interface TipTapBlogEditorProps {
  content: string;
  onContentChange: (content: string) => void;
}

const TipTapBlogEditor: React.FC<TipTapBlogEditorProps> = ({
  content,
  onContentChange,
}) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      TextAlign.configure({
        types: ["heading", "paragraph"],
        alignments: ["left", "center", "right", "justify"],
        defaultAlignment: "",
      }),
    ],
    content,
    onUpdate: ({ editor }) => {
      onContentChange(editor.getHTML());
    },
  });

  useEffect(() => {
    if (editor && editor.getHTML() !== content) {
      editor.commands.setContent(content);
    }
  }, [content, editor]);

  if (!editor) {
    return null;
  }

  const toggleBold = () => {
    editor.chain().focus().toggleBold().run();
  };

  const toggleItalic = () => {
    editor.chain().focus().toggleItalic().run();
  };

  const toggleHeading1 = () => {
    editor.chain().focus().toggleHeading({ level: 1 }).run();
  };

  const toggleHeading2 = () => {
    editor.chain().focus().toggleHeading({ level: 2 }).run();
  };

  const toggleBulletList = () => {
    editor.chain().focus().toggleBulletList().run();
  };

  const toggleTextAlignLeft = () => {
    editor.chain().focus().setTextAlign("left").run();
  };
  const toggleTextAlignCenter = () => {
    editor.chain().focus().setTextAlign("center").run();
  };

  return (
    <div className="blog-editor">
      <div className="control-group">
        <div className="button-group">
          <button
            className="button-ohne-funktion"
            style={{ backgroundColor: "white", color: "grey" }}
            onClick={() => {}}
            disabled={editor.can().toggleBold()}
          >
            styles
          </button>

          <button
            type="button"
            onClick={toggleBold}
            disabled={!editor.can().toggleBold()}
            className={editor.isActive("bold") ? "is-active" : ""}
          >
            Bold
          </button>

          <button
            onClick={toggleTextAlignLeft}
            disabled={!editor.can().setTextAlign("left")}
            className={
              editor.isActive({ textAlign: "left" }) ? "is-active" : ""
            }
          >
            Left
          </button>
          <button
            onClick={toggleTextAlignCenter}
            disabled={!editor.can().setTextAlign("center")}
            className={
              editor.isActive({ textAlign: "center" }) ? "is-active" : ""
            }
          >
            Center
          </button>

          <button
            type="button"
            onClick={toggleItalic}
            disabled={!editor.can().toggleItalic()}
            className={editor.isActive("italic") ? "is-active" : ""}
          >
            Italic
          </button>

          <button
            type="button"
            onClick={toggleHeading1}
            disabled={!editor.can().toggleHeading({ level: 1 })}
            className={
              editor.isActive("heading", { level: 1 }) ? "is-active" : ""
            }
          >
            H1
          </button>

          <button
            type="button"
            onClick={toggleHeading2}
            disabled={!editor.can().toggleHeading({ level: 2 })}
            className={
              editor.isActive("heading", { level: 2 }) ? "is-active" : ""
            }
          >
            H2
          </button>

          <button
            type="button"
            onClick={toggleBulletList}
            disabled={!editor.can().toggleBulletList()}
            className={editor.isActive("bulletList") ? "is-active" : ""}
          >
            Bullet List
          </button>
          <button
            type="button"
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            disabled={!editor.can().toggleOrderedList()}
            className={editor.isActive("orderedList") ? "is-active" : ""}
          >
            Ordered List
          </button>
        </div>
      </div>
      <EditorContent editor={editor} />
    </div>
  );
};

export default TipTapBlogEditor;
