import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/images/basarTextAndLogo.svg";
import "./Navbar.css";
import AboutPage from "../pages/AboutPage";
import ContactPage from "../pages/ContactPage";
import HomePage from "../pages/HomePage";
import BlogPage from "../pages/BlogPage";
import BlogPost from "../components/blog/BlogPost";
import BlogEditor from "../internal/internalPages/BlogEditor";
import PricePage from "../pages/PricePage";
import PartnerPage from "../pages/PartnerPage";
import VendorBrowserPage from "../pages/vendorBrowserPages/VendorBrowserPage";
import PartnerSearch from "./partner/PartnerSearch";
import PartnerDetail from "./partner/PartnerDetail";
import FAQPage from "../pages/FAQPage";
import ImprintPage from "../pages/legal/ImprintPage";
import TermsAndConditions from "../pages/legal/TermsAndConditions";
import PrivacyPolicyPage from "../pages/legal/PrivacyPolicyPage";
import CookiesPage from "../pages/legal/CookiesPage";
import InternalPage from "../internal/internalPages/InternalPage";
import { InternalNavbar } from "../internal/internalComponents/InternalNavbar";
import CRMPage from "../internal/internalPages/CRMPage";
import TMS from "../internal/internalPages/TranslationArea/TMS";
import PostNewTranslation from "../internal/internalPages/TranslationArea/PostNewTranslation";
import ResetPassword from "../pages/reset-password";

export const Navbar: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLinkClick = () => {
    setMenuOpen(false);
  };

  const InternalLayout: React.FC<{ children: React.ReactNode }> = ({
    children,
  }) => {
    return (
      <div>
        <InternalNavbar />
        <div className="internal-content">{children}</div>
      </div>
    );
  };

  return (
    <Router>
      <nav className="navbar">
        <Link to="/" className="logo">
          <Logo />
        </Link>
        <div className="menu-icon" onClick={toggleMenu}>
          {menuOpen ? "✕" : "☰"}
        </div>
        <ul className={`nav-links ${menuOpen ? "open" : ""}`}>
      
        <li>
            <Link onClick={handleLinkClick} to="/">
              Start
            </Link>
          </li>

          <li>
            <Link onClick={handleLinkClick} to="/partner">
              Partner Werden
            </Link>
          </li>
          <li>
            <Link onClick={handleLinkClick} to="/prices">
              Preise
            </Link>
          </li>
          <li>
            <Link onClick={handleLinkClick} to="/faq">
              FAQ
            </Link>
          </li>
       
          <li>
            <Link onClick={handleLinkClick} to="/blogs">
              Basar Blog
            </Link>
          </li>
          
        </ul>
      </nav>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/vendorBrowser" element={<VendorBrowserPage />} />

        <Route path="/partner" element={<PartnerPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/prices" element={<PricePage />} />
        <Route path="/blogs" element={<BlogPage />} />
        <Route path="/blog/:id" element={<BlogPost />} />
        <Route path="/" element={<PartnerSearch />} />
        <Route path="/partner-detail" element={<PartnerDetail />} />
        <Route path="/faq" element={<FAQPage />} />

        <Route path="/imprint" element={<ImprintPage />} />
        <Route path="/terms" element={<TermsAndConditions />} />
        <Route path="/privacy" element={<PrivacyPolicyPage />} />
        <Route path="/cookies" element={<CookiesPage />} />
        <Route path="/vendorBrowser" element={<VendorBrowserPage />} />
        <Route path="/reset-password" element={<ResetPassword />} />

        <Route
          path="/internal/*"
          element={
            <InternalLayout>
              <Routes>
                <Route path="/" element={<InternalPage />} />
                <Route path="/blogEditor" element={<BlogEditor />} />
                <Route path="/crm" element={<CRMPage />} />
                <Route path="/tms" element={<TMS />} />
                <Route
                  path="/tms/PostNewTranslation"
                  element={<PostNewTranslation />}
                />
              </Routes>
            </InternalLayout>
          }
        />
      </Routes>
    </Router>
  );
};
