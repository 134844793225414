import React from "react";
import "./FAQPage.css";
import Accordion from "../components/Accordion";
import { useTranslation } from "react-i18next";

const FAQPage: React.FC = () => {
  const { t } = useTranslation();
  // Define FAQ data using translation keys
  const faqData = [
    {
      category: t("faq.general.category"),
      questions: [
        {
          question: t("faq.general.questions.0.question"),
          answer: t("faq.general.questions.0.answer"),
        },
        {
          question: t("faq.general.questions.1.question"),
          answer: t("faq.general.questions.1.answer"),
        },
      ],
    },
    {
      category: t("faq.free_trial.category"),
      questions: [
        {
          question: t("faq.free_trial.questions.0.question"),
          answer: t("faq.free_trial.questions.0.answer"),
        },
      ],
    },
    {
      category: t("faq.membership.category"),
      questions: [
        {
          question: t("faq.membership.questions.0.question"),
          answer: t("faq.membership.questions.0.answer"),
        },
        {
          question: t("faq.membership.questions.1.question"),
          answer: t("faq.membership.questions.1.answer"),
        },
        {
          question: t("faq.membership.questions.2.question"),
          answer: t("faq.membership.questions.2.answer"),
        },
      ],
    },
    {
      category: t("faq.book_deal.category"),
      questions: [
        {
          question: t("faq.book_deal.questions.0.question"),
          answer: t("faq.book_deal.questions.0.answer"),
        },
        {
          question: t("faq.book_deal.questions.1.question"),
          answer: t("faq.book_deal.questions.1.answer"),
        },
        {
          question: t("faq.book_deal.questions.2.question"),
          answer: t("faq.book_deal.questions.2.answer"),
        },
        {
          question: t("faq.book_deal.questions.3.question"),
          answer: t("faq.book_deal.questions.3.answer"),
        },
      ],
    },
    {
      category: t("faq.redeem_deal.category"),
      questions: [
        {
          question: t("faq.redeem_deal.questions.0.question"),
          answer: t("faq.redeem_deal.questions.0.answer"),
        },
        {
          question: t("faq.redeem_deal.questions.1.question"),
          answer: t("faq.redeem_deal.questions.1.answer"),
        },
      ],
    },
    {
      category: t("faq.refer_basar.category"),
      questions: [
        {
          question: t("faq.refer_basar.questions.0.question"),
          answer: t("faq.refer_basar.questions.0.answer"),
        },
        {
          question: t("faq.refer_basar.questions.1.question"),
          answer: t("faq.refer_basar.questions.1.answer"),
        },
      ],
    },
  ];

  return (
    <div className="faq-page">
      <h1>{t("faq.title", "Frequently Asked Questions (FAQ)")}</h1>
      {faqData.map((category, index) => (
        <div key={index} className="faq-category">
          <h2>{category.category}</h2>
          {category.questions.map((faq, i) => (
            <Accordion key={i} question={faq.question} answer={faq.answer} />
          ))}
        </div>
      ))}
      <div>
        <label>{t("faq.contact.label", t("faq.no_answer_found"))}</label>
        <br />
        <button>{t("faq.contact.button", t("contact"))}</button>
      </div>
    </div>
  );
};

export default FAQPage;
