import React from "react";
import { ITranslation } from "./Configuration";

interface Props {
  translations: ITranslation[];
  handleEdit: (translation: ITranslation) => void;
}

const GetTranslationsTable: React.FC<Props> = ({ translations, handleEdit }) => {
  return (
    <table className="translationsTable">
      <thead>
        <tr>
          <th>Translation Key</th>
          <th>Target Culture</th>
          <th>Owner</th>
          <th>Usage Context</th>
          <th>Source Text</th>
          <th>Translated Text</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {translations.map((translation) => (
          <tr key={translation.TranslationKey}>
            <td>{translation.TranslationKey}</td>
            <td>{translation.TargetCulture}</td>
            <td>{translation.Owner}</td>
            <td>{translation.UsageContext}</td>
            <td>{translation.SourceText}</td>
            <td>{translation.TranslatedText}</td>
            <td>
              <button onClick={() => handleEdit(translation)} className="editButton">Edit</button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default GetTranslationsTable;

