// src/App.tsx
import React, { useState } from "react";
import "./App.css";
import { Navbar } from "./components/Navbar";
import Footer from "./components/Footer";
import "../src/i18n/i18n"; // Stelle sicher, dass i18n importiert und initialisiert wird

const App: React.FC = () => {
  return (
    <div className="App">
      <Navbar />
      <Footer />
    </div>
  );
};

export default App;
