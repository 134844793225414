import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";

const PrivacyPolicyPage: React.FC = () => {
  const [policyContent, setPolicyContent] = useState("");

  useEffect(() => {
    fetch("./docs/privacy-policy.md") // Adjust path if placed in public
      .then((response) => response.text())
      .then((text) => setPolicyContent(text));
  }, []);

  return (
    <div style={{ padding: "20px", maxWidth: "800px", margin: "auto" }}>
      <h1>Privacy Policy</h1>
      <ReactMarkdown>{policyContent}</ReactMarkdown>
    </div>
  );
};

export default PrivacyPolicyPage;
