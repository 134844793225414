import React from "react";
import HeaderTextComponent from "../styles/HeaderTextComponent";
import NormalTextComponent from "../styles/NormalTextComponent";
import "./PricePage.css";
import PriceCard from "../components/PriceCard";
import CallToActionApp from "../components/CallToActionApp";

const PricePage: React.FC = () => {
  return (
    <div className="container">
      {/* Beta Announcement */}
      <div className="header-container">
        <HeaderTextComponent level={2} title={""} seoDescription={""}>
          Willkommen zur kostenlosen Beta-Version von BASAR!
        </HeaderTextComponent>
        <div className="subheader-container">
          <NormalTextComponent size={"M"} title={""} seoDescription={""}>
            Derzeit ist die gesamte App kostenlos – sowohl für Nutzer als auch für Partner. Nutzen Sie diese Gelegenheit, BASAR zu erleben und aktiv zur Verbesserung der App beizutragen.
          </NormalTextComponent>
        </div>
      </div>

      {/* Why Free Section */}
      <div className="header-container">
        <HeaderTextComponent level={2} title={""} seoDescription={""}>
          Warum kostenlos?
        </HeaderTextComponent>
        <div className="subheader-container">
          <NormalTextComponent size={"M"} title={""} seoDescription={""}>
            Wir bieten BASAR aktuell kostenfrei an, um wertvolles Feedback zu sammeln und den lokalen Handel zu unterstützen. Ihre Meinung hilft uns dabei, die App weiter zu verbessern und Ihnen die besten Angebote direkt aus Ihrer Stadt zu liefern – und das völlig kostenfrei!
          </NormalTextComponent>
        </div>
      </div>

      {/* Price Cards */}
      <div className="price-container">
        <PriceCard
          offer={"Nutzer"}
          price={"0 €"}
          benefitOne={"Entdecke lokale Angebote, die nur hier zu finden sind"}
          benefitTwo={"Zugang zu exklusiven Deals und Rabatten"}
          benefitThree={"Einfach und flexibel – keine Bindung"}
          pricedetails={"Gratis während der Beta-Phase"}
          showBadge={true}
        />
        <PriceCard
          offer={"Partner"}
          price={"0 €"}
          benefitOne={"Erreiche neue Kunden kostenfrei"}
          benefitTwo={"Gestalte und passe deine Angebote flexibel an"}
          benefitThree={"Erhalte Feedback und baue deine Reichweite auf"}
          pricedetails={"Gratis während der Beta-Phase"}
          showBadge={true}
        />
      </div>

      {/* Call to Action */}
      <div
        style={{
          marginTop: "50px",
          marginBottom: "50px",
          backgroundColor: "white",
        }}
      >
        <CallToActionApp />
      </div>
    </div>
  );
};

export default PricePage;
