import React from 'react';
import './CallToActionApp.css';

const CallToActionApp: React.FC = () => {
  return (
    <div className="blog-advertisement">
      <div className="column">
        <h2>Bist du schon auf Basar?</h2>
        <p>
          Basar ist ein Marktplatz für lokale Händler. Lade dir jetzt die App herunter und entdecke die besten Angebote in deiner Nähe!
        </p>
        <button>Zur App</button>
      </div>
      <div className="column">
        <h2>Bist du Händler?</h2>
        <p>
          Verkaufe deine Produkte auf Basar und erreiche tausende Kunden in deiner Region!
        </p>
        <button>Als Händler registrieren</button>
      </div>
    </div>
  );
};

export default CallToActionApp;
