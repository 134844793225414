import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { API_BASE_URL, ITranslation, dropdownOptions } from "./Configuration";
import "./TMS.css";
import GetTranslationsTable from "./GetTranslationsTable";
import PostNewTranslation from "./PostNewTranslation";

const TranslationManagementSystem: React.FC = () => {
  const [translations, setTranslations] = useState<ITranslation[]>([]);
  const [editingTranslation, setEditingTranslation] =
    useState<ITranslation | null>(null);
  const [filters, setFilters] = useState<Partial<ITranslation>>({});
  const [searchText, setSearchText] = useState("");
  const [appliedFilters, setAppliedFilters] = useState<Partial<ITranslation>>(
    {}
  );
  const [appliedSearchText, setAppliedSearchText] = useState("");

  const [fetchingData, setFetchingData] = useState(false); // Zustand für das Laden der Daten

  useEffect(() => {
    // Kein automatisches Laden der Daten beim ersten Rendern
  }, []);

  const fetchTranslations = async () => {
    setFetchingData(true); // Daten werden geladen
    try {
      const response = await axios.get<ITranslation[]>(
        `${API_BASE_URL}/getTranslations`
      );
      setTranslations(response.data);
    } catch (error) {
      console.error("Error fetching translations:", error);
    } finally {
      setFetchingData(false); // Laden abgeschlossen
    }
  };

  const handleEdit = (translation: ITranslation) => {
    setEditingTranslation(translation);
  };

  const handleUpdate = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!editingTranslation) return;

    try {
      const response = await axios.put(
        `${API_BASE_URL}/updateTranslations`,
        JSON.stringify({
          TranslationKey: editingTranslation.TranslationKey,
          TargetCulture: editingTranslation.TargetCulture,
          TranslatedText: editingTranslation.TranslatedText,
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Update response:", response.data);
      fetchTranslations();
      setEditingTranslation(null);
    } catch (error) {
      console.error("Error updating translation:", error);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setEditingTranslation((prev) => (prev ? { ...prev, [name]: value } : null));
  };

  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const applyFilters = async () => {
    setAppliedFilters(filters);
    setAppliedSearchText(searchText);
    await fetchTranslations();
  };

  const filteredTranslations = translations.filter((translation) => {
    return (
      Object.entries(appliedFilters).every(
        ([key, value]) =>
          value === "" || translation[key as keyof ITranslation] === value
      ) &&
      (appliedSearchText === "" ||
        translation.SourceText.toLowerCase().includes(
          appliedSearchText.toLowerCase()
        ) ||
        translation.TranslatedText.toLowerCase().includes(
          appliedSearchText.toLowerCase()
        ))
    );
  });

  const sortedTranslations = filteredTranslations.sort((a, b) => {
    if (a.TranslationKey < b.TranslationKey) return -1;
    if (a.TranslationKey > b.TranslationKey) return 1;
    if (a.TargetCulture < b.TargetCulture) return -1;
    if (a.TargetCulture > b.TargetCulture) return 1;
    return 0;
  });

  return (
    <div className="container">
      <h1 className="title">Translation Management System</h1>
      <Link to="/internal/tms/PostNewTranslation" className="createButton">
        Add New Translation
      </Link>

      <div className="filterContainer">
        <h1>Filter & Search</h1>
        <input
          type="text"
          placeholder="Search in Source or Translated Text"
          value={searchText}
          onChange={handleSearchChange}
          className="searchInput"
        />
        {Object.entries(dropdownOptions).map(
          ([key, options]) =>
            key !== "SourceText" &&
            key !== "TranslatedText" && (
              <select
                key={key}
                name={key}
                value={filters[key as keyof ITranslation] || ""}
                onChange={handleFilterChange}
                className="filterDropdown"
              >
                <option value="">All {key}</option>
                {options.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            )
        )}
        <button onClick={applyFilters} className="applyFiltersButton">
          Apply Filters
        </button>
      </div>

      <PostNewTranslation />

      {editingTranslation && (
        <form onSubmit={handleUpdate} className="editForm">
          <h2>Edit Translation</h2>
          <p>
            {editingTranslation.TranslationKey} 🌎{" "}
            {editingTranslation.TargetCulture}
          </p>
          <input
            name="TranslatedText"
            value={editingTranslation.TranslatedText}
            onChange={handleInputChange}
            placeholder="Translated Text"
            required
          />
          <button type="submit" className="updateButton">
            Update Translation
          </button>
          <button
            type="button"
            onClick={() => setEditingTranslation(null)}
            className="cancelButton"
          >
            Cancel
          </button>
        </form>
      )}
      <div className="translationsContainer">
        <h1>Translations</h1>
        <button onClick={fetchTranslations} className="fetchButton">
          Fetch Translations
        </button>
        <GetTranslationsTable
          translations={sortedTranslations}
          handleEdit={handleEdit}
        />
      </div>
    </div>
  );
};

export default TranslationManagementSystem;
