import React from "react";
import "./HomePage.css"; // Stildefinitionen für die Homepage
import mockupImage from "../assets/images/mockup2PhonesApp.png"; // Bildimport für das Mockup
import HeaderTextComponent from "../styles/HeaderTextComponent";
import NormalTextComponent from "../styles/NormalTextComponent";
import googlePlayBadge from "../assets/images/badges/google-play-badge.png"; // Google Play Store badge
import appStoreBadge from "../assets/images/badges/app-store-badge.png"; // Apple App Store badge
import YoutubeVideo from "../components/YoutubeVideo";
import HowItWorksSteps from "../components/HowItWorkSteps";

const HomePage: React.FC = () => {
  return (
    <>
      <div className="homepage-container">
        <div className="two-column-section">
          <div className="left-column">
            <img
              src={mockupImage}
              alt="Mockup der App auf zwei Telefonen"
              className="section-image"
            />
          </div>
          <div className="right-column">
            <HeaderTextComponent
              level={1}
              title="Lokale Schätze entdecken - BASAR"
              seoDescription="Entdecke lokale Vielfalt und spare mit BASAR"
              style={{ marginBottom: 1 }}
            >
              <span style={{ color: "var(--basar-red)" }}>Spare</span> bei
              allem, was du brauchst
            </HeaderTextComponent>
            <NormalTextComponent
              size="L"
              title="Lokale Vielfalt entdecken - BASAR"
              seoDescription="Entdecke die Vielfalt des lokalen Handels mit BASAR"
            >
              - mit lokalen Gutscheinen ganz in deiner Nähe!
            </NormalTextComponent>
            {/* App store download buttons */}
            <div style={{ display: "flex", gap: "10px", marginTop: "20px", alignItems: "center", flexDirection: "column"}}>
              <a
                href="https://play.google.com/store/apps/details?id=com.yourapp"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={googlePlayBadge}
                  alt="Download on Google Play"
                  style={{ width: "150px" }}
                />
              </a>
              <a
                href="https://apps.apple.com/us/app/yourapp/id123456789"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={appStoreBadge}
                  alt="Download on the App Store"
                  style={{ width: "150px" }}
                />
              </a>
            </div>
          </div>
        </div>

        {/* Hier wird die HowItWorksSteps-Komponente integriert */}
        <HowItWorksSteps />

        <div className="homePageVideoSection">
          <HeaderTextComponent
            level={2}
            title="Was ist BASAR? - Entdecke lokale Angebote"
            seoDescription="Erfahre mehr über BASAR - Deine App für lokale Angebote"
            fontWeight="bold"
            style={{ textAlign: "center", marginBottom: 0 }}
          >
            Was ist BASAR?
          </HeaderTextComponent>
          <NormalTextComponent
            size="L"
            title="BASAR - Deine App für lokale Angebote"
            seoDescription="Erfahre mehr über BASAR"
          >
            - Deine App für lokale Angebote
          </NormalTextComponent>

        </div>
      </div>
    </>
  );
};

export default HomePage;
