import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./BlogPost.css";
import CallToActionApp from "../CallToActionApp";
import { IBlog } from "../../internal/internalComponents/blogEditor/BlogEditorModules";
import axios from "axios";

const BlogPost: React.FC = () => {
  const [blog, setBlog] = useState<IBlog | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        setLoading(true);
        console.log("Fetching blog with id:", id);
        const response = await axios.get(
          "https://9hpez190i6.execute-api.us-east-1.amazonaws.com/prod"
        );
        console.log("API response:", response.data);
        const blogs = JSON.parse(response.data.body);
        const selectedBlog = blogs.find((b: IBlog) => b.id === id);
        if (selectedBlog) {
          setBlog(selectedBlog);
        } else {
          setError("Blog not found");
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching blog:", error);
        setError("Failed to fetch blog. Please try again later.");
        setLoading(false);
      }
    };

    fetchBlog();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!blog) {
    return <div>Blog not found!</div>;
  }

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
  };

  const formattedDate =
    typeof blog.publishDate === "string"
      ? new Date(blog.publishDate).toLocaleDateString("de-DE", options)
      : "Kein Datum";

  return (
    <div className="blog-post">
      <h1 className="blog-title">{blog.title}</h1>
      <p className="blog-meta">Publizierungsdatum: {formattedDate}</p>
      <h2 className="blog-subtitle">{blog.subtitle}</h2>
      <img src={blog.titleImage} alt={blog.title} className="blog-image" />
      <div
        className="blog-content"
        dangerouslySetInnerHTML={{ __html: blog.contentText }}
      />
      <CallToActionApp />
    </div>
  );
};

export default BlogPost;
