import React from "react";
import NormalTextComponent from "../styles/NormalTextComponent";
import "./PriceCard.css";

interface PriceCardProps {
  offer: string;
  price: string;
  pricedetails: string;
  benefitOne: string;
  benefitTwo: string;
  benefitThree: string;
  showBadge: boolean;
}

const PriceCard: React.FC<PriceCardProps> = ({
  offer,
  price,
  benefitOne,
  benefitTwo,
  benefitThree,
  pricedetails,
  showBadge,
}: PriceCardProps) => {
  const redirectToAppStore = () => {
    const userAgent = navigator.userAgent;

    if (/android/i.test(userAgent)) {
      // Android-Gerät
      window.location.href =
        "https://play.google.com/store/apps/details?id=deine-app-paket-id";
    } else if (/iPad|iPhone|iPod/i.test(userAgent)) {
      // iOS-Gerät
      window.location.href =
        "https://apps.apple.com/app/deine-app-name/id123456789";
    } else {
      // Fallback für andere Geräte
      console.log("Gerät nicht erkannt oder nicht unterstützt.");
      // Hier könntest du eine alternative Handlung vornehmen, z.B. eine Fehlermeldung anzeigen
    }
  };
  return (
    <div className="price-card-container">
      {!showBadge && <div className="special-badge">Spare 20%</div>}
      <div className="price-card">
        <div className="price-card-info-area">
          <div className="first-row">
            <NormalTextComponent size={"XL"}>{offer}</NormalTextComponent>
            <div className="price-details-container">
              <NormalTextComponent size={"L"}>{price}</NormalTextComponent>
              <NormalTextComponent
                style={{
                  fontWeight: "lighter",
                  color: "#666",
                  textAlign: "end",
                  lineHeight: "1",
                  width: "50%",
                }}
                size={"XS"}
              >
                {pricedetails}
              </NormalTextComponent>
            </div>
          </div>
          <div className="second-row">
            <ul className="benefit-list">
              <li>
                <span role="img" aria-label="checkmark">
                  ✅
                </span>{" "}
                {benefitOne}
              </li>
              <li>
                <span role="img" aria-label="checkmark">
                  ✅
                </span>{" "}
                {benefitTwo}
              </li>
              <li>
                <span role="img" aria-label="checkmark">
                  ✅
                </span>{" "}
                {benefitThree}
              </li>
            </ul>
          </div>
        </div>
        <div className="price-card-action-area">
          <button
            className="call-to-action-button"
            onClick={redirectToAppStore}
          >
            <NormalTextComponent
              className="action-text"
              size={"M"}
              title={""}
              seoDescription={""}
            >
              Jetzt buchen
            </NormalTextComponent>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PriceCard;
