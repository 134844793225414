import React from "react";
import { Link } from "react-router-dom";
import "./InternalNavbar.css"; // Erstelle eine eigene CSS-Datei für die interne Navbar

export const InternalNavbar: React.FC = () => {
  return (
    <nav className="internal-navbar">
      <ul>
        <li>
          <Link to="/internal">Dashboard</Link>
        </li>
        <li>
          <Link to="/internal/blogEditor">Blog Editor</Link>
        </li>
        <li>
          <Link to="/internal/crm">CRM</Link>
        </li>
      </ul>
    </nav>
  );
};
