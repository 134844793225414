// Vorgegebene Auswahl
// zuüberarbeiten
export const dropdownOptions = {
  TranslationKey: ["BD.Common"],
  TargetCulture: ["de_DE", "en_US", "tr_TR"],
  Owner: ["Common", "Coupons"],
  UsageContext: ["StaticTranslation", "DynamicTranslation"],
};

// Lambda
export const API_BASE_URL =
  "https://e98k8x0vv0.execute-api.eu-west-1.amazonaws.com/prod";

export interface ITranslation {
  TranslationKey: string;
  TargetCulture: string;
  Owner: string;
  UsageContext: string;
  SourceText: string;
  TranslatedText: string;
}

export interface ICreateTranslation {
  TranslationKey: string;
  TargetCulture: string;
  Owner: Owner;
  UsageContext: string;
  SourceText: string;
  TranslatedText: string;
}


export type Owner = 'Common' | 'Vendor';
export const creationDropdownOptions = {
    TargetCulture: ["de_DE", "en_US", "tr_TR"],
    Owner: ["Common", "Vendor"],
    UsageContext: {
      Common: ["StaticTranslation"],
      Vendor: ["StaticTranslation", "Marketing"]
    },
    SourceCulture: ["en_US"],
  };