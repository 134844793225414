import React, { useState, useEffect, useRef } from "react";
import "./SearchBar.css";

export interface City {
  name: string;
  lat: number;
  lng: number;
}

interface SearchBarProps {
  items: City[];
  onSelect: (item: City) => void;
  placeholder?: string;
  icon?: string;
  maxResults?: number;
  label?: string;
}

const SearchBar: React.FC<SearchBarProps> = ({
  items,
  onSelect,
  placeholder = "Suche...",
  icon,
  label,
  maxResults = 5,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredItems, setFilteredItems] = useState<City[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const filtered = items
      .filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .slice(0, maxResults);
    setFilteredItems(filtered);
  }, [searchTerm, items, maxResults]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setIsOpen(true);
  };

  const handleSelect = (item: City) => {
    onSelect(item);
    setSearchTerm(item.name);
    setIsOpen(false);
  };

  return (
    <div className="search-bar-container" ref={wrapperRef}>
      <label className="search-label">🔎 {label} suchen</label>
      <div className="search-input-container">
        <input
          type="text"
          value={searchTerm}
          onChange={handleChange}
          placeholder={placeholder}
          className="search-input"
        />
      </div>
      {isOpen && filteredItems.length > 0 && (
        <ul className="search-results">
          {filteredItems.map((item) => (
            <li key={item.name} onClick={() => handleSelect(item)}>
              {item.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchBar;
