import React from "react";
import "./ContactPage.css";

const ContactPage: React.FC = () => {
  const phoneNumber = "+4915234679042"; // Ersetze dies durch die tatsächliche Telefonnummer
  const message =
    "Hallo, ich habe eine Kontaktanfrage und würde gerne mehr über eure Dienstleistungen erfahren.";

  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
    message
  )}`;

  return (
    <div className="contact-container">
      <h1>Kontaktieren Sie uns</h1>
      <p className="contact-description">
        Haben Sie Fragen zu unseren Services? Möchten Sie mehr über unsere
        Angebote erfahren? Unser Team steht Ihnen zur Verfügung! Kontaktieren
        Sie uns einfach und unkompliziert über WhatsApp, und wir melden uns so
        schnell wie möglich.
      </p>
      <a
        href={whatsappLink}
        target="_blank"
        rel="noopener noreferrer"
        className="whatsapp-button"
      >
        <button>Jetzt auf WhatsApp kontaktieren</button>
      </a>
    </div>
  );
};

export default ContactPage;
