import React, { useEffect, useRef, useState } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import "./UserVendorVote.css";
import { loadGoogleMaps } from "./googleMapsLoader";

const googleMapsApiKey = "AIzaSyCX7kdSkkuO9TbnuxQ3QIWI4NUvtUAepvk";

const UserVendorVote: React.FC = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const postalCodeRef = useRef<HTMLInputElement>(null);

  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [displayErrorMessage, setDisplayErrorMessage] =
    useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [placeId, setPlaceId] = useState<string | null>(null);

  useEffect(() => {
    loadGoogleMaps().then(() => {
      if (inputRef.current) {
        const autocomplete = new google.maps.places.Autocomplete(inputRef.current, {
          types: ["establishment"],
          fields: ["place_id"],
        });
        setAutocomplete(autocomplete);

        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();
          if (place.place_id) {
            setPlaceId(place.place_id);
          } else {
            alert("Ort konnte nicht gefunden werden.");
          }
        });
      }
    });
  }, []);
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const emailInput = emailRef.current?.value;
    const postalCodeInput = postalCodeRef.current?.value;
    if (placeId && emailInput && postalCodeInput) {
      setLoading(true);
      setErrorMessage(null); // Reset error message
      try {
        const userIpAddress = await getUserIpAddress();
        const response = await fetch(
          "https://8qgjrz3qxk.execute-api.eu-west-1.amazonaws.com/prod/",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              placeId: placeId,
              userEmail: emailInput,
              userZipCode: postalCodeInput,
              userIpAddress: userIpAddress,
            }),
          }
        );

        if (response.ok) {
          const responseBody = await response.json();
          if (responseBody.statusCode === 400) {
            setErrorMessage(
              responseBody.message ||
                "IP Adresse hat schon diesen Ort abgestimmt"
            );
            setDisplayErrorMessage(true);
            setShowConfirmation(false); // Versteckt die Bestätigungsnachricht
          } else {
            setShowConfirmation(true);
            setDisplayErrorMessage(false); // Versteckt die Fehlermeldung
            console.log(response.body);
            console.log(response);
            console.log("Daten versendet");
          }
        } else {
          console.error("Fehler beim Absenden irgendwie");
          alert("Fehler");
        }
      } catch (error) {
        console.error("Fehler:", error);
        alert("Es ist ein Fehler aufgetreten.");
      }
      setLoading(false);
    } else {
      alert("Bitte wähle einen Ort aus und trage deine E-Mail Adresse ein.");
    }
  };

  async function getUserIpAddress() {
    const response = await fetch("https://api.ipify.org?format=json");
    const { ip } = await response.json();
    console.log("IP ADRESSE HIER>>", response);
    return ip;
  }

  return (
    <div className="input-wrapper">
      {loading && <div className="loading-indicator"></div>}
      {!loading && showConfirmation && (
        <div className="confirmation-message">
          <p>Danke für deine Empfehlung!</p>
        </div>
      )}
      {!loading && !showConfirmation && (
        <form onSubmit={handleSubmit}>
          <div className="input-container">
            <div className="input-field">
              <label htmlFor="vendor-search">
                🔎 Lokales Geschäft empfehlen
              </label>
              <input
                ref={inputRef}
                id="vendor-search"
                type="text"
                placeholder="Geschäft suchen"
                className="autocomplete-input"
                required
              />
            </div>
            <div className="input-field">
              <label htmlFor="email">📧 E-Mail Adresse</label>
              <input
                ref={emailRef}
                id="email"
                type="email"
                placeholder="Deine E-Mail Adresse z.B. basar@mail.de"
                className="autocomplete-input"
                required
              />
            </div>
            <div className="input-field">
              <label htmlFor="plz">🔢 Postleitzahl</label>
              <input
                ref={postalCodeRef}
                type="text"
                id="plz"
                name="plz"
                placeholder="Deine Postleitzahl eingeben z.B. 45881"
                pattern="[0-9]{5}"
                className="autocomplete-input"
                required
              />
            </div>
            <button type="submit" >
              Absenden
            </button>
            <p className="consent-text">
              Mit Klick auf "Absenden" stimmst du zu, dass wir dich für
              Werbezwecke kontaktieren dürfen.
            </p>
            {displayErrorMessage && errorMessage && (
              <p className="error-message">{errorMessage}</p>
            )}
          </div>
        </form>
      )}
    </div>
  );
};

export default UserVendorVote;
